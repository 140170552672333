.blog-view-container {
    p {
        font-weight: normal !important;
    }
    h3 {
        font-size: "60px";
    }
}

.blog-view-spinner-container {
    height: 100vh;
}
